<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="text-align: right; z-index: 1000;">
       <el-button  size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
       <el-button size="small" type="warning"  v-if="!isdata" @click="klfun()">{{this.$t('All.克隆')}}</el-button>
       <el-button size="small" type="primary"  v-if="isdata"  @click="IQC_Admin()">{{this.$t('system.tijiao')}}</el-button>
       <el-button size="small" type="primary"  v-if="!isdata" @click="UserDataUp()">{{this.$t('system.gxup')}}</el-button>
       <el-button size="small" type="primary"   @click="IsUpload(3,FileList3)">{{this.$t('system.fjian')}}</el-button>
       <el-button size="small" type="danger" v-if="!isdata" @click="Delete()">{{this.$t('system.del')}}</el-button>
     </div>
     <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
     <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
     <h4 style="text-align: center;">{{this.$t('system.lururen')}}：{{form.Enteredby}}</h4>
     <div style=" padding: 10px; text-align: center; line-height: 2vw;">
       <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
           <tr>
              <td  colspan="2">{{this.$t('IQC.检验基准表')}}</td>
              <td >{{this.$t('IQC.工程图面')}}</td>
              <td >{{this.$t('IQC.承认书')}}</td>
              <td  colspan="2">{{this.$t('IQC.品名')}}</td>
              <td  colspan="2">{{this.$t('IQC.料号')}}</td>
              <td  colspan="2">{{this.$t('IQC.供应商')}}</td>

           </tr>
           <tr>
            <td  colspan="2" class="tdd"></td>
            <td class="tdd"></td><!--<el-input v-model="form.Drawing_Surface"></el-input>-->
            <td class="tdd"></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Product_name"></el-input></td>
            <td  colspan="2" class="tdd"><el-input onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="form.Item_No=form.Item_No.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" v-model="form.Item_No"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Supplier"></el-input></td>
           </tr>
           <tr>
            <td colspan="2">{{$t('All.标准样品编号')  }}</td>
             <td >{{ $t('All.物料单位') }}</td>
             <td >{{this.$t('IQC.交货数')}}</td>
             <td >{{this.$t('IQC.入库数')}}</td>
             <td >{{this.$t('IQC.抽样数')}}</td>
             <td colspan="2">{{this.$t('IQC.抽样类型')}}</td>
             <td colspan="2">{{this.$t('IQC.订单号')}}</td>
           </tr>
           <tr>
             <td  colspan="2" class="tdd"><el-input v-model="form.Standard_sample"></el-input></td>
             <td class="tdd"> <!--<el-input v-model="form.Company"></el-input>-->
              <el-select v-model="form.Company" filterable allow-create :placeholder="$t('All.单位')" @change="chuangjcompany" :style="{width: '100%'}">
                <el-option label="G" value="G"></el-option>
                <el-option label="KG" value="KG"></el-option>
                <el-option label="PC" value="PC"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="KM" value="KM"></el-option>
              </el-select>
            </td>
             <td class="tdd"><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="form.Delivery"></el-input></td>
             <td class="tdd"><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="form.Warehousing"></el-input></td>
             <td class="tdd"><el-input v-model="form.Sampling"></el-input></td>
             <td  colspan="2" class="tdd">
                    <el-select v-model="form.Sampling_type" :placeholder="this.$t('IQC.请选择类型')">
                    <el-option :label="this.$t('IQC.加严')" :value="this.$t('IQC.加严')"></el-option>
                    <el-option :label="this.$t('IQC.正常')" :value="this.$t('IQC.正常')"></el-option>
                    <el-option :label="this.$t('IQC.减量')" :value="this.$t('IQC.减量')"></el-option>
                    </el-select>
              </td>
             <td  colspan="2" class="tdd"><el-input v-model="form.Order_Number"></el-input></td>
           </tr>
           <tr>
             <td colspan="10"><el-input v-model="form.Standard"><template slot="prepend">{{this.$t('IQC.执行标准')}}</template></el-input></td>
           </tr>
           <tr>
             <td >{{this.$t('IQC.检验项目')}}</td>
             <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
             <td colspan="1">{{ $t('All.检验工具') }}</td>
             <td colspan="2">{{ $t('All.判定基准') }}</td>
             <td colspan="1">{{ $t('All.实验频率') }}</td>
             <td colspan="1">{{ $t('All.判断类别') }}</td>
             <td colspan="1">{{this.$t('IQC.备注')}}</td>
             <td colspan="1">{{ $t('All.操作') }}</td>
           </tr>
           <tr v-for="(item, index) in form.Testlist" :key="'Testlist' + index">
             <td colspan="1"><el-input v-model="item.Title"></el-input></td> <!--:rowspan="item.Index" v-if="(item.Index !== 0)"-->
             <td colspan="2"><el-input type="textarea" :rows="3" v-model="item.Standard"></el-input></td>
             <td colspan="1"><el-input type="textarea" :rows="3" v-model="item.Tool"></el-input></td>
             <td colspan="2"><el-input type="textarea" :rows="3" v-model="item.Benchmark"></el-input></td>
             <td colspan="1">
              <el-select v-model="item.Frequency" :placeholder="$t('All.请选择频率')">
                  <el-option
                    v-for="i in $PublicJs.Frequency"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                  </el-option>
                </el-select>
            </td>
             <td colspan="1">
                <el-select v-model="item.Determinetype" :placeholder="$t('IQC.请选择类型')">
                  <el-option
                    v-for="i in $PublicJs.Determinetype"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                  </el-option>
                </el-select>
             </td>
             <td colspan="1"><el-input type="textarea" :disabled="(item.Determinetype == 1)" :rows="3" v-model="item.Remarks"></el-input></td>
             <td colspan="1" class="tdd" style="min-width: 150px;"><el-button type="primary"  @click="addDomainmax(item,index)" size="mini">{{ $t('All.新增') }}</el-button><el-button type="primary" v-show="form.Testlist.length > 1"  @click="removeDomainmax(item)" size="mini">{{ $t('All.删除') }}</el-button></td>
           </tr>
           <tr>
               <td class="tdd" rowspan="2">{{this.$t('IQC.规格要求')}}</td>
               <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
               <td colspan="7" class="tdd"><el-input v-model="form.Measuring"></el-input></td>
           </tr>
           <tr>
            <td colspan="1" class="tdd" >{{ $t('All.单位')}}/{{$t('All.标注')}}</td>
              <td colspan="5" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
           </tr>
           <tr v-for="(text, index) in form.NewIQCParcss" :key="index">
               <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Specification" ></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Units_Dimensions"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text1"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text2"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text3"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text4"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text5"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Max"></el-input></td>
               <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Min"></el-input></td>
               <td class="tdd" style="min-width: 150px;"><el-button type="primary"  @click="addDomain(text,index)" size="mini">{{ $t('All.新增') }}</el-button><el-button type="primary" v-show="form.NewIQCParcss.length > 1"  @click="removeDomain(text)" size="mini">{{ $t('All.删除') }}</el-button></td>
           </tr>
           <tr>
            <td colspan="10"><el-input type="textarea" :rows="6" v-model="form.Beizhu"></el-input></td>
          </tr>
       </table>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Hz}}</span></el-col>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Jyy}}</span></el-col>
       </el-row>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
         <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-37-K</span></el-col>
       </el-row>
     </div>
    <iqcUpload v-if="isboll" :Number = "form.Number" :Type = "Type" :FileList = "FileList" :FalseUpload="falseUpload"></iqcUpload>
    <el-dialog
    :title="this.$t('All.克隆')"
      :visible.sync="dialogVisiblekl"
      width="500px"
      :before-close="handleClose" append-to-body>
      <el-form :model="klform">
        <el-form-item :label="$t('All.模版单号')">
            {{ klform.Number }}
          </el-form-item>
        <el-form-item :label="$t('All.克隆后的料号')">
            <el-input v-model="klform.Item_No" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblekl = false">{{$t('All.取消')}}</el-button>
          <el-button type="primary" @click="klIQC_Admin()">{{$t('All.确定')}}</el-button>
        </div>
    </el-dialog>
  </div>
 </template>
<script>
import iqcUpload from './Upload/NewiqcAdminUpload.vue'
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {
        // 系统单号
        Number: '',
        // 检验基准表
        Inspection_Table: '',
        // 工程图面
        Drawing_Surface: '',
        // 承认书
        Acknowledgment: '',
        // 品名
        Product_name: '',
        // 规格
        Pddsc2: '',
        // 料号
        Item_No: '',
        // 供应商
        Supplier: '',
        // 标准样品编号
        Standard_sample: '',
        // 物料单位
        Company: '',
        // 交货数
        Delivery: '',
        // 入库数
        Warehousing: '',
        // 抽样数
        Sampling: '',
        // 抽样类型
        Sampling_type: '',
        // 订单号
        Order_Number: '',
        // 执行标准
        Standard: '',
        // 量测仪器编号
        Measuring: '',
        // 备注
        Beizhu: this.$t('IQC.beizhu'),
        // 录入人
        Enteredby: '',
        //
        Factory: '',
        // 判断类别，0=自检，1=实验室
        Determinetype: 0,
        //
        Testlist: [
          {
            //
            Number: '',
            //
            Index: 0,
            //
            Title: '',
            //
            Standard: '',
            Frequency: 0,
            //
            Tool: '',
            //
            Benchmark: '',
            Determinetype: 0,
            //
            Determine: '',
            //
            Remarks: ''
          }
        ],
        NewIQCParcss: [
          {
            //
            Number: '',
            //
            Index: 0,
            //
            Specification: '',
            //
            Units_Dimensions: '',
            //
            Text1: '',
            Text2: '',
            Text3: '',
            Text4: '',
            Text5: '',
            //
            Max: '',
            //
            Min: '',
            //
            Determine: ''
          }
        ]
      },
      isdata: false,
      isqpisboot: false,
      FileList0: [],
      FileList1: [],
      FileList2: [],
      FileList3: [],
      Type: 0,
      isboll: false,
      klform: {},
      dialogVisiblekl: false
    }
  },
  ...mapMutations(['UserData', 'NewIQCAdminNumberFun', 'Productname']),
  components: {
    iqcUpload
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    if (this.$store.state.UserData === null) {
      this.isdata = true
      this.form.Enteredby = this.$store.state.Login.Username
      this.form.Item_No = this.$store.state.Productname
      this.form.Factory = this.$store.state.Login.Factory
      this.QcCount()
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
      this.IQCAdminGetAllFiles(this.form.Number)
    }
  },
  mounted () {
  },
  methods: {
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    IsUpload (type, filelist) {
      this.Type = type
      this.FileList = filelist
      this.isboll = true
    },
    falseUpload () {
      this.isboll = false
    },
    async QcCount () {
      try {
        this.form.Number = this.$store.state.NewIQCAdminNumber
        this.GetSdaten(this.form.Item_No)
        var Testlist = [
          {
            Number: this.$store.state.NewIQCAdminNumber,
            Index: 0,
            Title: '',
            Standard: '',
            Tool: '',
            Benchmark: '',
            Frequency: 0,
            Determinetype: 0,
            Determine: '',
            Remarks: ''
          }
        ]
        this.form.Testlist = Testlist
        var NewIQCParcss = [
          {
            Number: this.$store.state.NewIQCAdminNumber,
            Index: 0,
            Specification: '',
            Units_Dimensions: '',
            Text1: '',
            Text2: '',
            Text3: '',
            Text4: '',
            Text5: '',
            Max: '',
            Min: '',
            Determine: ''
          }
        ]
        this.form.NewIQCParcss = NewIQCParcss
        this.IQCAdminGetAllFiles(this.form.Number)
      } catch (error) {
        console.log(error)
        alert(this.$t('All.网络异常'))
      }
    },
    async GetSdaten (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/GetSdaten?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response == null) {
          return this.$message.error(this.$t('All.系统暂无') + row + this.$t('All.的样品'))
        } else {
          this.form.Standard_sample = res.response
        }
      } catch (error) {
        alert(error)
      }
    },
    addDomain (item, index) {
      const contr = {
        //
        Number: item.Number,
        //
        Index: index + 1,
        //
        Specification: item.Specification,
        //
        Units_Dimensions: item.Units_Dimensions,
        //
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        //
        Max: '',
        //
        Min: '',
        //
        Determine: ''
      }
      this.form.NewIQCParcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
        this.form.NewIQCParcss[i].Index = i
      }
    },
    removeDomain (item) {
      var index = this.form.NewIQCParcss.indexOf(item)
      if (index !== -1) {
        this.form.NewIQCParcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
        this.form.NewIQCParcss[i].Index = i
      }
    },
    ...mapMutations(['UserData', 'TabComponentFun']),
    async IQC_Admin () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCadminadd', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.新IQC模版'), 'NewIQCAdminData', 'IQCAdminData')
        this.RemoveTab(this.$t('All.新IQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async IQCAdminGetAllFiles (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.get('/api/Newiqc/IQCAdminGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        if (res.response.length > 0) {
          res.response.forEach(element => {
            element.url = this.$Upload + '/' + element.url
          })
        }
        loadingInstance.close()
        if (res.response.length > 0) {
          for (let index = 0; index < res.response.length; index++) {
            if (res.response[index].Type === 0) {
              this.FileList0.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type === 1) {
              this.FileList1.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type === 2) {
              this.FileList2.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type === 3) {
              this.FileList3.splice(0, 0, res.response[index])
            }
          }
        }
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async UserDataUp () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCadminup', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.新IQC模版'), 'NewIQCAdminData', 'IQCAdminData')
        this.RemoveTab(this.$t('All.新IQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async Delete () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCadmindel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.新IQC模版'), 'NewIQCAdminData', 'IQCAdminData')
        this.RemoveTab(this.$t('All.新IQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    addDomainmax (item, index) {
      const contr = {
        Number: item.Number,
        Index: index + 1,
        Title: item.Title,
        Standard: '',
        Tool: '',
        Benchmark: '',
        Determinetype: 0,
        Determine: '',
        Remarks: ''
      }
      this.form.Testlist.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Testlist.length; i++) {
        this.form.Testlist[i].Index = i
      }
    },
    removeDomainmax (item) {
      var index = this.form.Testlist.indexOf(item)
      if (index !== -1) {
        this.form.Testlist.splice(index, 1)
      }
      for (let i = 0; i < this.form.Testlist.length; i++) {
        this.form.Testlist[i].Index = i
      }
    },
    fileopen (url) {
      console.log(this.FileList1)
      alert(url)
      console.log(url)
      // window.open(url, '_blank')
    },
    chuangjcompany () {
      var inputValue = this.form.Company
      // 移除非字母字符
      inputValue = inputValue.replace(/[^A-Za-z]/g, '')
      // 转换为大写
      inputValue = inputValue.toUpperCase()
      this.form.Company = inputValue
    },
    async klfun () {
      this.klform = { ...this.form }
      this.klform.Item_No = ''
      this.klform.Inspection_Table = ''
      this.klform.Drawing_Surface = ''
      this.klform.Acknowledgment = ''
      this.klform.Enteredby = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const QcCountLogin = {
        Login: this.$store.state.Login,
        Type: 'IQC'
      }
      const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.response)
      }
      loadingInstance.close()
      this.klform.Number = res.response
      for (let index = 0; index < this.klform.Testlist.length; index++) {
        this.klform.Testlist[index].Number = this.klform.Number
      }
      for (let index = 0; index < this.klform.NewIQCParcss.length; index++) {
        this.klform.NewIQCParcss[index].Number = this.klform.Number
      }
      this.dialogVisiblekl = true
    },
    async klIQC_Admin () {
      if (this.klform.Item_No.length < 1) return this.$message.error(this.$t('All.请填写料号后在提交'))
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCadminadd', this.klform)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.新IQC模版'), 'NewIQCAdminData', 'IQCAdminData')
        this.dialogVisiblekl = false
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    }
  }
}
</script>
 <style lang="less" scoped>
   .wbobye{
   position:absolute/fixed;
   left:0;
   right:0;
   top:0;
   bottom:0;
   margin:auto;
   position: absolute;
   height: 100%;
   width: 100%;
   background-color: rgba(255, 255, 255);
   min-width: 903px;
 }
   .el-col {
     border: 1px solid;
     margin-right: -1px;
      margin-bottom: -1px;
     line-height: 4.5vw;
     font-size: 2vw;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space:nowrap;
   }
   .grid-content {
     min-height: 36px;
   }
   .bg-purple-light{

   }
   .row-bg {
     background-color: #f9fafc;
   }
 </style>
